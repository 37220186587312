@tailwind base;
@tailwind components;
@tailwind utilities;

iframe {
  background-color: none transparent !important;
}

.custom-player {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;
}

.custom-player audio {
  width: 300px;
}

.textarea::placeholder {
  font-style: italic;
  color: #a0aec0; /* Lighter color for the placeholder text */
}
